body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  /* font-family: "Roboto", Arial, Helvetica, sans-serif; */
  box-sizing: border-box;
}

input.error {
  border-color: red;
}

.input-feedback {
  color: red;
  height: 5px;
  margin-top: 12px;
}

.myMain {
  background-color: #f2f2f2;
  min-height: calc(100vh - 159px);
}

.mainup_text {
  padding: 0 14px;
}

.mainup_text h1 {
  font-size: 36px;
  color: white;
  line-height: 42px;
}

.mainup_text span {
  font-size: 24px;
  color: white;
  line-height: 28px;
}

.mainup_img {
  margin-left: 200px;
}

.mainup_img img {
  width: 243px;
  height: 287px;
}

.main_down {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.showPanel {
  padding-bottom: 1px;
  background-color: #ffffff;
}

.fileContainer .deleteImage {
  background: #f47721 !important;
}

.actionFormat {
  display: flex;
  justify-content: center;
}

.thumbs {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
}

.pictureStream {
  height: 400px;
}

/****************************************** For Desktop >981px ******************************************/
@media (min-width: 981px) {
  .devicelistdropdown {
    display: none;
  }

  .devicelisttabs {
    display: flex;
  }

  .body_panel {
    padding: 20px;
  }

  .sortArea {
    display: none;
  }

  .aboutcontent {
    display: flex;
    padding: 0px 30px;
    padding-bottom: 30px;
    justify-content: center;
  }

  .contactusBody {
    margin: 0 18%;
  }

  .livePicture {
    height: 400px;
  }

  .firstButtonGroup {
    margin-left: 15px;
  }

  .secondButtonGroup {
    margin-left: 15px;
  }

  /* .page_title {
    flex-direction: row;
  } */

  .mapDevList {
    padding-left: 2px;
    padding-right: 2px;
  }

  .showPanel {
    width: 600px;
    margin: 30px auto auto;
  }

  .menubar {
    height: 800px;
  }

  .sub_content {
    padding: 20px 0px 20px 0px;
  }

  .modelWidth {
    width: 10%;
  }

  .nameWidth {
    width: 10%;
  }

  .addressWidth {
    width: 25%;
  }

  .mainup {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: #f47721;
    margin: auto;
  }

  .mainup > img {
    height: 500px;
  }
}
/******************************************* For Desktop END *******************************************/

/******************************************* MOBILE RESPONSIVE *******************************************/
@media (max-width: 980px) {
  .body_panel {
    padding: 0px;
  }

  .sub_content {
    padding-top: 0px;
  }

  .page_title {
    padding-left: 20px;
  }

  .mainup_img {
    margin-left: 0px;
  }

  .aboutcontent {
    flex-direction: column;
    padding: 0 20px;
  }

  .aboutimage {
    display: flex;
  }

  .aboutimage img {
    margin: auto;
  }

  .contactusBody {
    margin: 0 20px;
  }

  .devicelistdropdown {
    display: block;
  }

  .devicelisttabs {
    display: none;
  }

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
    text-align: center;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
  }

  .tableWithMobile tr {
    display: flex;
    flex-flow: row wrap;
  }

  .table td {
    padding: 3px;
    border: none;
    position: relative;
  }

  .controlBtn {
    margin-right: 2px !important;
    padding: 6px;
  }

  .sortArea {
    display: block;
  }

  .firstButtonGroup {
    margin-left: 15px;
  }

  .secondButtonGroup {
    margin-left: 15px;
    margin-top: 5px;
  }

  .livePicture {
    width: 320px;
    height: 240px;
  }

  .mapArea,
  .listArea {
    min-width: 100%;
  }

  .mapArea {
    padding: 0px;
  }

  .devdetailleft,
  .devdetailright {
    width: 50%;
  }

  .picModal {
    width: 320px;
    height: 240px;
  }

  .showPanel {
    margin: 10px;
  }

  .sortArea .row .col-5 {
    padding: 0 0 0 6px;
  }

  .pictureStream {
    height: 240px;
  }

  .menu_Bar {
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;
    /* position: absolute; */
  }

  /* .react-bootstrap-table table {
    table-layout: auto;
  } */

  .fullWidth {
    width: 100%;
  }

  .halfWidth {
    width: 50%;
  }

  .tenWidth {
    width: 8%;
  }

  .mapWidth {
    width: 60%;
  }

  .idWidth {
    width: 32%;
  }

  .modelWidth {
    width: 45%;
  }

  .fieldName {
    font-weight: bold;
    width: 30%;
  }

  .selection-cell {
    padding-top: 6px !important;
  }

  .sharedEmailInput {
    width: 75%;
    padding: 0px;
  }

  .sharedShareBtn {
    width: 25%;
  }

  /* .sharedUser {
    width: 60%;
  } */

  .sharedRemove {
    width: 40%;
    text-align: right;
  }

  .sharemodallist div {
    padding-left: 2px;
    padding-right: 0px;
    overflow-x: scroll;
  }

  .mainup {
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    justify-content: center;
    background-color: #f47721;
    height: 500px;
    margin: auto;
    background-size: 100vw auto;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
  }

  .mainup > img {
    width: 70%;
  }
}

/*****************************************************/
@media (max-width: 575px) {
  .react-bootstrap-table-pagination-list.col-md-6.col-xs-6.col-sm-6.col-lg-6 {
    display: flex;
    justify-content: center;
  }

  .mainup > img {
    width: 100%;
  }
}
/****************************************** MOBILE RESPONSIVE END ******************************************/

.productlisttitle {
  display: flex;
  justify-content: center;
  font-size: 24px;
  line-height: 28px;
  margin-top: 10px;
  color: #333333;
}

.productlisttitle {
  display: flex;
  justify-content: center;
}

.productscontent {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 30px;
}

.products {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  margin: 10px 14px;
}

.productlist {
  /* padding-top: 38px; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.products img {
  width: 290px;
  height: 218px;
}

.footer {
  background: #f2f2f2;
}

.footer_menus {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer_menus a {
  color: #f47721;
  margin: 20px 15px;
}

.footer_copyright {
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  margin: 6px 0px;
}

.terms {
  display: flex;
  justify-content: center;
  /* background-color: #F2F2F2; */
  padding-top: 20px;
  /* min-height: calc(100vh - 159px); */
}

.terms_main {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-top: 4px solid #e0e0e0;
  width: 990px;
}

.rect {
  height: 4px;
  background: #e0e0e0;
  width: 100%;
}

.termstitle {
  font-size: 36px;
  padding: 30px 30px;
  color: #333333;
}

.termsdate {
  padding: 10px 20px;
  color: #828282;
  font-size: 14px;
}

.termstext {
  padding: 10px 20px;
  color: #333333;
}

.aboutimage img {
  width: 290px;
  height: 218px;
}

.contactus_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  width: 990px;
  padding-bottom: 20px;
}

.contactusTitle {
  margin: 40px 18% 30px 18%;
  font-size: 36px;
  color: #333;
  /* margin-bottom: 30px; */
}

.contactusBody span {
  color: #333;
}

.contactusBody form {
  margin-top: 20px;
}

.loginpage {
  display: flex;
  justify-content: center;
}

.mainlogin {
  margin-top: 20px;
  background-color: white;
  width: 550px;
}

.forgot {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.mainforgot {
  margin-top: 20px;
  background-color: white;
  width: 600px;
  height: 360px;
}

.forgotcontent {
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
}

.forgotcontent input[type="email"] {
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  height: 34px;
  padding-left: 10px;
}

.forgotcontent span {
  color: #333333;
}

#forgotsubmit {
  margin-top: 20px;
  width: 125px;
  height: 34px;
  background-color: #f47721;
  border: 1px solid #f47721;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.seperator {
  height: 1px;
  background-color: #e0e0e0;
}

.menubar {
  background-color: white;
  padding: 0px;
}

.menubar > a {
  color: #333333;
  font-weight: bold;
  padding: 14px 0px 14px 20px;
}

.menubar > a:active {
  color: #333333;
  background-color: #f2f2f2;
}

.menubar > a svg {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

#email,
#password {
  padding-left: 8px;
}
.mainpanel {
  background-color: #f2f2f2;
  height: 868px;
}

.body_panel {
  background-color: #f2f2f2;
}

.centre_panel {
  background-color: #f2f2f2;
}

.page_title {
  color: #333333;
  font-size: 24px;
  margin-bottom: 10px;
}

.page_content {
  border: 1px solid #e0e0e0;
  border-top: 4px solid #e0e0e0;
  background-color: white;
  padding: 20px;
  padding-top: 10px;
}

.nav-tabs a {
  color: #f47721;
}

.nav-tabs a:active {
  color: #333333;
}

.sub_menus {
  display: inline;
}

.sub_menus {
  color: #f47721;
  font-weight: bold;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.sub_menus a:first-child {
  border-right: 1px solid #e0e0e0;
}

.sub_menus > a:active {
  color: #333333;
}

.sub_title {
  font-size: 24px;
  color: #333333;
}

.normalButton {
  background-color: #f47721;
  font-weight: bold;
  border-color: #f47721;
}

.normalButton:hover {
  background-color: #f47721 !important;
  border-color: white !important;
}
.normalButton:disabled {
  background-color: #f47721 !important;
  border-color: white !important;
}

.normalButton:active {
  background-color: #f47721 !important;
  border-color: white !important;
}

.normalButton:focus {
  border-color: white !important;
  background-color: #f47721 !important;
}

.buttonWeek.active {
  background-color: #f47721 !important;
  border-color: white !important;
  color: white !important;
}

.buttonWeek {
  background-color: #e0e0e0 !important;
  border-color: white !important;
}

.cancelButton {
  background-color: white;
  color: #333333;
  font-weight: bold;
  border: 1px solid #bdbdbd;
}

.cancelButton:hover {
  background-color: white;
  color: #333333;
}

.cancelButton:focus {
  background-color: white;
  color: #333333;
}

.cancelButton:active {
  background-color: white;
  color: #333333;
}

.validStatusEmail {
  border-color: #ced4da !important;
}

.form-label {
  font-weight: bold;
}

.grayButton {
  background-color: white;
  color: #333333;
  font-weight: bold;
  border: 1px solid #bdbdbd;
  opacity: 50%;
}

.page-item.active .page-link {
  background-color: #f47721 !important;
  border-color: #f47721 !important;
}

.page-link {
  color: #333333;
}

.pac-container {
  z-index: 1051;
}

.actionBtn {
  padding: 4px 8px;
}

.controlBtn > svg {
  margin-right: 6px;
  margin-bottom: 3px;
  width: 14px;
  height: 14px;
}

.camera_pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.devdetailleft {
  text-align: right;
  color: #333333;
  font-weight: bold;
}

.devdetailright {
  color: #333333;
}

.sharemodallist {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.sharemodallistheader {
  margin-top: 10px;
  padding-bottom: 15px;
  border-bottom: 2px solid #e0e0e0;
}

.sharemodallistheader div {
  font-weight: bold;
}

.devicealertlist {
  padding: 14px 0px;
  box-shadow: inset 0px -1px 0px #e0e0e0;
}

.selection-cell input[type="checkbox"]:checked ~ td::after {
  background-color: #f47721;
}

.usertable {
  overflow-x: scroll;
}

.react-bootstrap-table {
  overflow-x: auto !important;
}

.controlBtns:hover {
  color: #343a40;
  background-color: white;
}

.controlBtns:active {
  color: #343a40 !important;
  background-color: white !important;
}

.btn-outline-dark.active {
  color: #343a40 !important;
  background-color: white !important;
}

/* .livePicture {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
} */

.picThumb {
  height: 150px;
}

.picModal {
  margin-bottom: 10px;
}

.pictureStream {
  display: flex;
  justify-content: center;
}

/* ******************************************* */
.custom-checkbox > [type="checkbox"],
.custom-checkbox > label {
  margin-bottom: 0px !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-checkbox > [type="checkbox"]:not(:checked),
.custom-checkbox > [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
.custom-checkbox > [type="checkbox"]:not(:checked) + label,
.custom-checkbox > [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
}
.custom-checkbox > [type="checkbox"]:not(:checked) + label:before,
.custom-checkbox > [type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -9px;
  width: 17px;
  height: 17px;
  border: 1px solid #ddd;
  background: #ffffff;
  border-radius: 2px;
}
.custom-checkbox > [type="checkbox"]:not(:checked) + label:after,
.custom-checkbox > [type="checkbox"]:checked + label:after {
  font: normal normal normal 12px/1 "Glyphicons Halflings";
  content: "\e013";
  position: absolute;
  top: 50%;
  margin-top: -7px;
  left: 2px;
  color: #94c947;
  transition: all 0.2s;
}

.custom-checkbox > [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.custom-checkbox > [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.custom-checkbox > [type="checkbox"][data-indeterminate] + label:after,
.custom-checkbox > [type="checkbox"][data-indeterminate] + label:after {
  content: "\2212";
  left: 2px;
  opacity: 1;
  transform: scale(1);
}

.custom-checkbox > [type="checkbox"]:disabled:not(:checked) + label:before,
.custom-checkbox > [type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  background-color: #eeeeee;
  border-color: #eeeeee;
  cursor: not-allowed;
  opacity: 1;
  color: #dadada;
}
.custom-checkbox > [type="checkbox"]:disabled:checked + label:after {
  color: #dadada;
  cursor: not-allowed;
}
.custom-checkbox > [type="checkbox"]:disabled + label {
  color: #aaa;
  cursor: not-allowed;
}
.custom-checkbox > [type="checkbox"]:checked:focus + label:before,
.custom-checkbox > [type="checkbox"]:not(:checked):focus + label:before {
  border: 1px solid #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.custom-checkbox > label:hover:before {
  border: 1px solid #88d2ff !important;
}
.custom-checkbox
  > [type="checkbox"]:disabled:not(:checked)
  + label:hover:before,
.custom-checkbox > [type="checkbox"]:disabled:checked + label:hover:before {
  border: 1px solid #e4e4e4 !important;
}

.modal-header {
  border-top: 4px solid #e0e0e0;
}

.detect_tooltip > .tooltip-inner {
  background-color: #f47721;
  font-weight: bold;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #f47721;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #f47721;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #f47721;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #f47721;
}

.bottomMenuLinks a {
  margin: 0 2px 0 2px;
  color: #333;
}

.menuHeader {
  color: #333;
  position: absolute;
  top: -69px;
  font-size: 36px;
  margin-left: 10px;
  z-index: 2;
}

.classbold {
  font-weight: bold;
}

.btnLabel {
  border-radius: 30px;
  background-color: #3f4257;
  color: white;
  padding: 6px 23px;
}

.productimagelist {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.productimage {
  position: relative;
  width: 140px;
  padding: 10px;
  margin: 10px;
  background: #edf2f6;
  display: flex;
  align-items: center;
  box-shadow: 5px 5px 2px #888888;
}

.productimage img {
  width: 100%;
}

.closeimage {
  position: absolute;
  top: -15px;
  right: -15px;
  color: white;
  background-color: #f47721;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  font-weight: 700;
  line-height: 26px;
  width: 28px;
  height: 28px;
}

.prodSepName {
  margin-top: 10px;
  font-weight: bold;
  color: #f47721;
}

.prodSepLine {
  width: 100%;
  border-top: 1px solid #f47721;
  margin: 10px 0;
}
