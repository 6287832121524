@import "~antd/dist/antd.css";

.menu {
  /* padding: 0px 100px; */
  border-bottom: solid 1px #e8e8e8;
  /* overflow: auto; */
  box-shadow: 0 0 30px #f3f1f1;
  background-color: white;
  height: 69px;
  display: flex;
  justify-content: center;
}

.menu_loggedin {
  padding: 0px 10px;
  border-bottom: solid 1px #e8e8e8;
  box-shadow: 0 0 30px #f3f1f1;
  background-color: white;
  height: 69px;
}
/*************************** mobile ****************************/
@media (max-width: 980px) {
  .menu_loggedin {
    justify-content: space-between;
  }

  .menu {
    justify-content: flex-start;
    padding-left: 10px;
  }
}

/*************************** For desktop ****************************/
@media (min-width: 981px) {
  .menu_right {
    float: right;
  }

  .menu_right {
    margin-left: 280px;
  }
}
.menu__logo {
  display: flex;
  margin-right: 20px;
}

.menu__logo a {
  align-self: center;
}

.menu__logo a img {
  width: 140px;
}

.notiGroup {
  display: flex;
  flex-direction: row;
}

.menu_left {
  padding-top: 10px;
  width: 33%;
}

.menu_left ul {
  border: none;
}

.signupbutton {
  border: 1px solid gray;
  border-radius: 4px;
  width: 80px;
  height: 34px;
  font-weight: bold;
  color: #333333;
  background-color: white;
}

.signinbutton {
  border: 1px solid #f47721;
  border-radius: 4px;
  background-color: #f47721;
  color: white;
  width: 80px;
  height: 34px;
  font-weight: bold;
  margin-left: 8px;
  margin-top: 17px;
}

.dropdown-toggle:after {
  display: none;
}

.dropdown-menu {
  font-size: 14px;
}

.dropdown.nav-item {
  align-self: center;
}

.noti_last {
  width: 50%;
  color: #f47721;
  font-weight: bold;
}

.noti_share {
  color: #f47721;
  font-weight: bold;
}

.noti_mark {
  float: right;
  margin-top: -33px;
  text-align: right;
}

.noti_time {
  color: #828282;
}

.noti_dev {
  color: #333;
  font-weight: bold;
  /* float: left; */
  display: inline-block;
}

.orange_dot {
  width: 14px;
  height: 14px;
  background: #f47721;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.empty_space {
  display: inline-block;
  width: 14px;
  margin-right: 10px;
}

.normalButton {
  background-color: #f47721;
  font-weight: bold;
  border-color: #f47721;
}

.normalButton:hover {
  background-color: #f47721 !important;
  border-color: white !important;
}

.normalButton:active {
  background-color: #f47721 !important;
  border-color: white !important;
}

.normalButton:focus {
  background-color: #f47721 !important;
  border-color: white !important;
}

.cancelButton {
  background-color: white;
  color: #333333;
  font-weight: bold;
  border: 1px solid #bdbdbd;
}

.cancelButton:hover {
  background-color: white;
  color: #333333;
}

.cancelButton:active {
  background-color: white;
  color: #333333;
}

.myNavs {
  border: none !important;
}

.myNavs a {
  color: #333333 !important;
  font-weight: bold;
}

.myNavs a:focus,
.myNavs a:active {
  color: #f47721 !important;
  font-weight: bold;
}

/* Overlay */
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  /* background-color: rgba(255, 255, 255, 0.9); */
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  width: 100%;
  padding-left: 20px;
}

.overlay a {
  padding: 20px;
  text-decoration: none;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 24px;
  color: #333;
  font-weight: bold;
  padding: 12px;
}
